import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import { useWindowSize } from '@app/hooks/useWindowSize';
import { calculateWindowSize } from '@app/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setWindowSize } from '@app/store/reducers/ui';
import { init as initAxios } from "./services/AxiosServices";

import Dashboard from '@pages/Dashboard';
import NotFound from '@app/pages/NotFound';
import Profile from '@pages/profile/Profile';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

import ListUsers from './pages/user/ListUsers';
import AddUser from './pages/user/AddUser';
import EditUser from './pages/user/EditUser';

import ListRoles from './pages/role/ListRoles';
import AddRole from './pages/role/AddRole';
import EditRole from './pages/role/EditRole';

import ListPermission from './pages/permission/ListPermissions';
import AddPermission from './pages/permission/AddPermission';
import EditPermission from './pages/permission/EditPermission';
import EditRolePermission from './pages/role/EditRolePermission';
import ChooseRole from './modules/choose-role/ChooseRole';

import ListPostcodes from './pages/postcode/ListPostcodes';
import AddPostcode from './pages/postcode/AddPostcode';
import EditPostcode from './pages/postcode/EditPostcode';
import PostcodeHistory from './pages/postcode/PostcodeHistory';
import AttachFranchise from './pages/postcode/AttachFranchise';
import DetachFranchise from './pages/postcode/DetachFranchise';

import ListFranchise from './pages/franchise/ListFranchise';
import AddFranchise from './pages/franchise/AddFranchise';
import EditFranchise from './pages/franchise/EditFranchise';

import ListSubjects from './pages/subject/ListSubjects';
import AddSubject from './pages/subject/AddSubject';
import EditSubject from './pages/subject/EditSubject';

import ListModules from './pages/module/ListModules';
import AddModule from './pages/module/AddModule';
import EditModule from './pages/module/EditModule';
import SortModulesOrder from './pages/module/SortModulesOrder';

import ListOtherItems from './pages/other-items/ListOtherItems';
import EditItem from './pages/other-items/EditItem';
import AddItem from './pages/other-items/AddItem';

import ListCenters from './pages/center/ListCenters';
import AddCentre from './pages/center/AddCentre';
import EditCentre from './pages/center/EditCentre';

import ListRegions from './pages/region/ListRegions';
import ListAreas from './pages/area/ListAreas';
import ListHandbooks from './pages/handbook/ListHandbooks';
import ListSets from './pages/set/ListSets';
import ViewFranchise from './pages/franchise/ViewFranchise';
import ViewUser from './pages/user/ViewUser';
import ListBlocks from './pages/block/ListBlocks';
import Invoice from './pages/order-form/Invoice';
import OrderForm from './pages/order-form/OrderForm';
import ViewOrder from './pages/order-form/ViewOrder';
import LinkFranchise from './pages/franchise/LinkFranchise';
import ListPricingPlans from './pages/pricing-plan/ListPricingPlans';
import AddPlan from './pages/pricing-plan/AddPlan';
import LinkFranchiseToPlan from './pages/pricing-plan/LinkFranchiseToPlan';
import ViewPlan from './pages/pricing-plan/ViewPlan';
import AttachSubjectToPlan from './pages/pricing-plan/AttachSubjectToPlan';
import ListHandbookSupplements from './pages/handbook/ListHandbookSupplements';
import InProgressOrders from './pages/order-form/InProgressOrders';
import DispatchedCancelledOrders from './pages/order-form/DispatchedCancelledOrders';
import ViewInvoice from './pages/order-form/ViewInvoice';
import ListSetting from './pages/setting/ListSetting';
import PlanHistory from './pages/pricing-plan/PlanHistory';
import ViewDemographic from './pages/postcode/ViewDemographic';
import StripeContainer from './pages/stripe/StripeContainer';
import ManageShippingAddress from './pages/franchise/ManageShippingAddress';
import ListStudentCounts from './pages/student/ListStudentCounts';
import FranchiseDashboard from './pages/franchise/FranchiseDashboard';
import ListDocs from './pages/ops-manual/ListDocs';
import EditCentreContent from './pages/center/EditCentreContent';
import ViewCentre from './pages/center/ViewCentre';
import StudentCountWrapper from './pages/student/submit-student-count/StudentCountWrapper';
import ListStudentCountTransactions from './pages/student/ListStudentCountTransactions';
import { StripeContainer as StudentCountStripeContainer }  from './pages/student/submit-student-count/stripe/StripeContainer';
import ViewStudCountTransaction from './pages/student/submit-student-count/ViewStudCountTransaction';
import AddStudent from './pages/student-details/AddStudent';
import ListStudents from './pages/student-details/ListStudents';
import EditStudent from './pages/student-details/EditStudent';
import ListLearningPathways from './pages/learning-pathway/ListLearningPathways';
import ViewPathwayDetails from './pages/learning-pathway/ViewPathwayDetails';
import ViewStudent from './pages/student-details/ViewStudent';
import ListParents from './pages/student-details/ListParents';
import EditGuardian from './pages/student-details/EditGuardian';
import ViewGuardian from './pages/student-details/ViewGuardian';
import ListSettingPattern from './pages/setting-pattern/ListSettingPattern';
import AddPattern from './pages/setting-pattern/AddPattern';
import EditPattern from './pages/setting-pattern/EditPattern';
import StudentWorkSummary from './pages/student-details/set-work/StudentWorkSummary';
import StudentWorkHistory from './pages/student-details/set-work/StudentWorkHistory';
import SetWorkWrapper from './pages/student-details/set-work/SetWorkWrapper';
import AdhocPrinting from './pages/student-details/AdhocPrinting';
import EnrollToCamp from './pages/student-details/EnrollToCamp';
import ListCamps from './pages/camp/ListCamps';
import ViewCamp from './pages/camp/ViewCamp';
import ListEnrolledStudents from './pages/camp/ListEnrolledStudents';
import ListPrintHistory from './pages/printing-reports/ListPrintHistory';
import PrintHistoryDetails from './pages/printing-reports/PrintHistoryDetails';
import ViewFMSContract from './pages/franchise/fmsContract/ViewFMSContract';
import AddFMSContract from './pages/franchise/fmsContract/AddFMSContract';
import EditFMSContract from './pages/franchise/fmsContract/EditFMSContract';
import ListNotes from './pages/franchise/notes/ListNotes';
import AddNote from './pages/franchise/notes/AddNote';
import ViewNoteDetails from './pages/franchise/notes/ViewNoteDetails';
import UpdateDBSCert from './pages/user/UpdateDBSCert';
import ListStudentInvoices from './pages/student-details/invoices/ListStudentInvoices';
import ViewStudentInvoice from './pages/student-details/invoices/ViewStudentInvoice';
import WorkSummary from './pages/parent-portal/student-work/WorkSummary';
import WorkHistory from './pages/parent-portal/student-work/WorkHistory';
import ListAllNotes from './pages/franchise/notes/ListAllNotes';
import RestrictedFranchiseCarts from './pages/order-form/RestrictedFranchiseCarts';
import ViewRestrictedCart from './pages/order-form/ViewRestrictedCart';

const App = () => {
	initAxios();
	const windowSize = useWindowSize();
	const screenSize = useSelector((state: any) => state.ui.screenSize);
	const dispatch = useDispatch();

	useEffect(() => {
		const size = calculateWindowSize(windowSize.width);
		if (screenSize !== size) {
			dispatch(setWindowSize(size));
		}
	}, [windowSize]);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/login" element={<PublicRoute />}>
					<Route path="/login" element={<Login />} />
				</Route>
				<Route path="/register" element={<PublicRoute />}>
					<Route path="/register" element={<Register />} />
				</Route>
				<Route path="/forgot-password" element={<PublicRoute />}>
					<Route path="/forgot-password" element={<ForgetPassword />} />
				</Route>
				<Route path="/recover-password" element={<PublicRoute />}>
					<Route path="/recover-password" element={<RecoverPassword />} />
				</Route>
				<Route path="/choose-role" element={<PublicRoute />}>
					<Route path="/choose-role" element={<ChooseRole />} />
				</Route>

				<Route path="/" element={<PrivateRoute />}>
					<Route path="/" element={<Main />}>
						<Route path="*" element={<NotFound />} /> 
						<Route path="/" element={<Dashboard />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/users" element={<ListUsers />} />
						<Route path="/users/add" element={<AddUser />} />
						<Route path="/users/edit/:id" element={<EditUser />} />
						<Route path="/users/view/:id" element={<ViewUser />} />
						<Route path="/users/dbs/:id" element={<UpdateDBSCert />} />
						<Route path="/roles" element={<ListRoles />} />
						<Route path="/roles/add" element={<AddRole />} />
						<Route path="/roles/edit/:id" element={<EditRole />} />
						<Route path="/roles/edit/permission/:id" element={<EditRolePermission />} />
						<Route path="/permissions" element={<ListPermission />} />
						<Route path="/permissions/add" element={<AddPermission />} />
						<Route path="/permissions/edit/:id" element={<EditPermission />} />
						<Route path="/postcodes" element={<ListPostcodes />} />
						<Route path="/postcodes/add" element={<AddPostcode />} />
						<Route path="/postcodes/edit/:id" element={<EditPostcode />} />
						<Route path="/postcodes/history/:id" element={<PostcodeHistory />} />
						<Route path="/postcodes/attach-franchise/:id" element={<AttachFranchise />} />
						<Route path="/postcodes/detach-franchise/:id" element={<DetachFranchise />} />
						<Route path="/postcodes/demographics" element={<ViewDemographic />} />
						<Route path="/franchise" element={<ListFranchise />} />
						<Route path="/franchise/add" element={<AddFranchise />} />
						<Route path="/franchise/edit/:id" element={<EditFranchise />} />
						<Route path="/franchise/dashboard/:id" element={<FranchiseDashboard />} />
						<Route path="/franchise/view/:id" element={<ViewFranchise />} />
						<Route path="/franchise/link/:id" element={<LinkFranchise />} />
						<Route path="/franchise/shipping-address/:franchise_id" element={<ManageShippingAddress />} />
						<Route path="/franchise/view-contract/:id" element={<ViewFMSContract />} />
						<Route path="/franchise/add-contract/:id" element={<AddFMSContract />} />
						<Route path="/franchise/edit-contract/:id" element={<EditFMSContract />} />
						<Route path="/subjects" element={<ListSubjects />} />
						<Route path="/subjects/add" element={<AddSubject />} />
						<Route path="/subjects/edit/:id" element={<EditSubject />} />
						<Route path="/modules" element={<ListModules />} />
						<Route path="/modules/add" element={<AddModule />} />
						<Route path="/modules/edit/:id" element={<EditModule />} />
						<Route path="/modules/reorder" element={<SortModulesOrder />} />
						<Route path="/other-items" element={<ListOtherItems />} />
						<Route path="/other-items/add" element={<AddItem />} />
						<Route path="/other-items/edit/:id" element={<EditItem />} />
						<Route path="/centers" element={<ListCenters />} />
						<Route path="/centers/add" element={<AddCentre />} />
						<Route path="/centers/edit/:id" element={<EditCentre />} />
						<Route path="/centers/view/:id" element={<ViewCentre />} />
						<Route path="/centers/edit/page-content/:id" element={<EditCentreContent />} />
						<Route path="/regions" element={<ListRegions />} />
						<Route path="/student-counts" element={<ListStudentCounts />} />
						<Route path="/submit-student-counts" element={<StudentCountWrapper />} />
						<Route path="/student-count-transactions" element={<ListStudentCountTransactions />} />
						<Route path="/student-counts/pay/:id" element={<StudentCountStripeContainer /> } />
						<Route path="/student-counts/view/:id" element={<ViewStudCountTransaction /> } />
						<Route path="/areas" element={<ListAreas />} />
						<Route path="/handbooks" element={<ListHandbooks />} />
						<Route path="/handbook-supplements" element={<ListHandbookSupplements />} />
						<Route path="/sets" element={<ListSets />} />
						<Route path="/blocks" element={<ListBlocks />} />
						<Route path="/in-progress-orders" element={<InProgressOrders />} />
						<Route path="/dispatched-cancelled-orders" element={<DispatchedCancelledOrders />} />
						<Route path="/restricted-franchise-carts" element={<RestrictedFranchiseCarts />} />
						<Route path="/restricted-franchise-carts/:franchise_id/view/:user_id" element={<ViewRestrictedCart />} />
						<Route path="/orders/place" element={<OrderForm />} />
						<Route path="/orders/view/:id" element={<ViewOrder />} />
						<Route path="/orders/view/invoice/:id" element={<ViewInvoice />} />
						<Route path="/orders/pay/:id" element={<StripeContainer />} />
						<Route path="/invoice" element={<Invoice />} />
						<Route path="/pricing-plans" element={<ListPricingPlans />} />
						<Route path="/pricing-plans/add" element={<AddPlan />} />
						<Route path="/pricing-plans/link/:id" element={<LinkFranchiseToPlan />} />
						<Route path="/pricing-plans/view/:id" element={<ViewPlan />} />
						<Route path="/pricing-plans/attach/subject/:id" element={<AttachSubjectToPlan />} />
						<Route path="/pricing-plans/history/:id" element={<PlanHistory />} />
						<Route path="/settings" element={<ListSetting />} />
						<Route path="/ops-manual" element={<ListDocs />} />
						<Route path="/add-student" element={<AddStudent />} />
						<Route path="/students" element={<ListStudents />} />
						<Route path="/student/edit/:id" element={<EditStudent />} />
						<Route path="/learning-pathway" element={<ListLearningPathways />} />
						<Route path="/learning-pathway/details/:id" element={<ViewPathwayDetails />} />
						<Route path="/student/view/:id" element={<ViewStudent />} />
						<Route path="/parents" element={<ListParents />} />
						<Route path="/parent/edit/:id" element={<EditGuardian />} />
						<Route path="/parent/view/:id" element={<ViewGuardian />} />
						<Route path="/setting-patterns" element={<ListSettingPattern />} />
						<Route path="/setting-patterns/add" element={<AddPattern />} />
						<Route path="/setting-patterns/edit/:id" element={<EditPattern />} />
						<Route path="/student/set-work/:id" element={<SetWorkWrapper />} />
						<Route path="/student/work-summary/:id" element={<StudentWorkSummary />} />
						<Route path="/student/work-history/:id" element={<StudentWorkHistory />} />
						<Route path="/student/adhoc-printing/:id" element={<AdhocPrinting />} />
						<Route path="/student/enroll-in-camp/:id" element={<EnrollToCamp />} />
						<Route path="/camps" element={<ListCamps />} />
						<Route path="/camps/view/:id" element={<ViewCamp />} />
						<Route path="/camps/enrolled-students/:id" element={<ListEnrolledStudents />} />
						<Route path="/print-summary" element={<ListPrintHistory />} />
						<Route path="/print-summary/view/:id" element={<PrintHistoryDetails />} />
						<Route path="/student-invoices" element={<ListStudentInvoices />} />
						<Route path="/student-invoices/view/:id" element={<ViewStudentInvoice />} />
						<Route path="/franchise/:franchise_id/notes" element={<ListNotes />} />
						<Route path="/franchise/:franchise_id/notes/add" element={<AddNote />} />
						<Route path="/franchise/:franchise_id/notes/:note_id/view" element={<ViewNoteDetails />} />
						<Route path="/work-summary" element={<WorkSummary />} />
						<Route path="/work-history" element={<WorkHistory />} />
						<Route path="/franchise-notes" element={<ListAllNotes />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default App;
