import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { genarateStudentRecurringInvoices, getStudentInvoicesWithPagination } from '@app/services/student';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { getAllActiveFranchise } from '@app/services/franchise';
import Select from "react-select";
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import { getAllGrades } from '@app/services/school';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const ListStudentInvoices = () => {

    const selectRef = useRef(null);
    const selectRefGen = useRef(null);
    const [students, setStudents] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isInvoiceLoading, setInvoiceLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({studentName: '', franchiseId: '', yearGroupId: '', invoiceNumber: '', invoiceAmount: '', invoiceStatus: ''});
    const [franchises, setFranchises] = useState([]);
    const [franchisesGen, setFranchisesGen] = useState([]);
    const [grades, setGrades] = useState([]);
    const studentStatus = [{label:'Paid', value:'paid'}, {label:'Unpaid', value:'unpaid'}]
    const canAccess = useSelector((state: any) => state.auth.permissions);
    const [months, setMonths] = useState([]);
    const allMonths = [{id: 1, name:'January'}, {id: 2, name:'February'}, {id: 3, name:'March'}, 
                    {id: 4, name:'April'}, {id: 5, name:'May'}, {id: 6, name:'June'},
                    {id: 7, name:'July'},{id: 8, name:'August'},{id: 9, name:'September'},
                    {id: 10, name:'October'}, {id: 11, name:'November'}, {id: 12, name:'December'}];
    const years = Array.from( { length: 2 }, (_, index) => new Date().getFullYear() - index );
    
    const navigate = useNavigate()
    const [t] = useTranslation()
    useEffect(() => {
        if(canAccess.student.invoices == false){
            toast.error(t('auth.noAccess'))
            navigate('/')
        }
    }, [])
    
    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }

    useEffect(() => {
        getAllStudentInvoices(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllActiveFranchiseData()
        getAllActiveGradesData()
        setMonths(allMonths.filter((month) => {
            return month.id <= new Date().getMonth() + 1
        }))
    },[]);

    const getAllStudentInvoices = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const studentsResp = await getStudentInvoicesWithPagination(page, perPage, sortField, sortDirection, search);
            if (studentsResp.success === true) {
                setLoading(false);
                setStudents(studentsResp.collections.data);
                setTotalRows(studentsResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(studentsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setFranchises(resp?.collections)
                let arr = [...resp?.collections]
                arr.unshift({id: 'all', name: 'All', enabled_recurring_invoice: true})
                setFranchisesGen(arr.filter((franchise) => franchise.enabled_recurring_invoice == true))
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const getAllActiveGradesData = async () => {
        try{
            const resp = await getAllGrades();
            if (resp.success === true) {
                setGrades(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        }catch (error: any){
            toast.error(error?.message || 'Something went wrong');
        }
    }

    const columns = [
        {
            name: 'Invoice Id',
            selector: (row: { invoice_number: string;}) => row.invoice_number,
            sortable: true,
            sortField: 'invoice_number',
            wrap: true,
        },
        {
            name: 'Student Name',
            selector: (row: { student_name: string;}) => row.student_name,
            sortable: true,
            sortField: 'student_name',
            wrap: true,
        },
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name ? row.franchise_name : '---',
            sortable: true,
            sortField: 'franchise_name',
            wrap: true,
        },
        {
            name: 'Year Group',
            selector: (row: {year_group: string;}) => row.year_group ?? '---',
            sortable: true,
            sortField: 'year_group',
            wrap: true,
        },
        {
            name: 'Invoice Amount',
            selector: (row: { invoice_amount: string, currency: string }) => row.invoice_amount,
            format: (row: { invoice_amount: string, currency: string }) => <CurrencyFormat value={ (parseFloat(row.invoice_amount)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ row.currency + ' '} />,
            sortable: true,
            sortField: 'invoice_amount',
        },
        {
            name: 'Invoice Duration',
            selector: (row: {invoice_from_date: string, invoice_to_date: string}) => moment(row.invoice_from_date).format('Do MMM YYYY') + ' - ' + moment(row.invoice_to_date).format('Do MMM YYYY'),
            sortable: false,
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { invoice_status: string; }) => row.invoice_status,
            cell: (row: { invoice_status: string; }) => (
                row.invoice_status === 'paid' ? <span className="badge bg-success">Paid</span> : <span className="capitalize badge bg-danger">{row.invoice_status}</span>
            ),
            sortable: true,
            sortField: 'invoice_status',
        },
        {
            name: 'Created On',
            selector: (row: { created_at: string; }) => row.created_at ? moment(row.created_at).format('DD-MM-YYYY') : '---',
            sortable: true,
            sortField: 'created_at',
        },
        {  
            name: 'Action',
            cell: (row: { id: number, status: string;}) => (
              <>
                { canAccess?.student['view-student-invoice'] &&
                    <Link
                        to={`/student-invoices/view/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="View Invoice Details"
                        target={'_blank'}
                    >
                    <i className="fas fa-eye mr-2"/>
                    </Link>
                }
              </>
            ),
        },
    ];

    const handlePageChange = page => {
        getAllStudentInvoices(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllStudentInvoices(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllStudentInvoices(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch({studentName: '', franchiseId: '', yearGroupId: '', invoiceNumber: '', invoiceAmount: '', invoiceStatus: ''});
    };

    const onChangeFranchise = (option) => {
        setSearch({ ...search, franchiseId: option.id });
    };

    const generateRecurringInvoices = async (payload) => {
        try {
            setInvoiceLoading(true)
            const resp = await genarateStudentRecurringInvoices(payload);
            if (resp?.success === true) {
                setInvoiceLoading(false)
                resetForm()
                selectRefGen.current.setValue('');
                toast.success(resp?.message)
                getAllStudentInvoices(1, perPage, sortField, sortDirection);
            } else {
                setInvoiceLoading(false)
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setInvoiceLoading(false)
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            franchise_id: '',
            year: '',
            month: '',
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
            year: Yup.string().required('Please select year'),
            month: Yup.string().required('Please select month'),
        }),
        onSubmit: (values) => {
            generateRecurringInvoices(values)
        },
        enableReinitialize: true,
    });

    const onYearChange = (e) => {
        setFieldValue('year', e.target.value)
        if(e.target.value != ''){
            if(e.target.value == new Date().getFullYear()){
                setMonths(allMonths.filter((month) => {
                    return month.id <= new Date().getMonth() + 1
                }))
            }else if(e.target.value < new Date().getFullYear()){
                setMonths(allMonths)
            }
        }else{
            setMonths([])
        }
    }

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Student Invoices</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Student Invoices</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Student Invoices</h3>
                                        <div className="col-lg-12">
                                            <form onSubmit={handleSubmit}>
                                            <div className='row justify-content-end'>
                                                <div className="col-sm-3">
                                                    <Select
                                                        ref={selectRefGen}
                                                        name="franchiseId"
                                                        placeholder="Search By Franchise"
                                                        options={franchisesGen}
                                                        getOptionValue={(option)=>`${option['id']}`}
                                                        getOptionLabel={(option)=>`${option['name']}`}
                                                        onChange={ (option) => { 
                                                            if(option){
                                                                setFieldValue("franchise_id", option.id)
                                                            }else{
                                                                setFieldValue("franchise_id", '')
                                                            }
                                                        }}
                                                        styles={customStylesSelect}
                                                    />
                                                    {(touched.franchise_id && errors.franchise_id) && (
                                                        <div className="text-danger">
                                                            {errors.franchise_id}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-sm-3">
                                                    <select
                                                        className="form-control"
                                                        name="year"
                                                        onChange={ (e) => { onYearChange(e) }} 
                                                        value={values.year}
                                                    >
                                                        <option value="">Select Year</option>
                                                        {years.map((year) => (
                                                            <option key={year} value={year}>
                                                                {year}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {(touched.year && errors.year) && (
                                                        <div className="text-danger">
                                                            {errors.year}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-sm-3">
                                                    <select
                                                        className="form-control"
                                                        name="month"
                                                        onChange={handleChange} 
                                                        value={values.month}
                                                    >
                                                        <option value="">Select Month</option>
                                                        { months.map((obj) => (
                                                            <option key={obj.name} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {(touched.month && errors.month) && (
                                                        <div className="text-danger">
                                                            {errors.month}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-sm-2">
                                                    <Button type="submit" isLoading={isInvoiceLoading} className="btn btn-primary mr-2">Generate</Button>
                                                </div>
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <input type="text" name='invoiceNumber' className="form-control" value={search.invoiceNumber} onChange={(e) => setSearch({ ...search, invoiceNumber: e.target.value })} placeholder="Search By Invoice Id" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='studentName' className="form-control" value={search.studentName} onChange={(e) => setSearch({ ...search, studentName: e.target.value })} placeholder="Search By Student" />
                                            </div>
                                            <div className="col-sm-2">
                                                <Select
                                                    ref={selectRef}
                                                    name="franchiseId"
                                                    placeholder="Search By Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => {onChangeFranchise(option)}}
                                                    styles={customStylesSelect}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="yearGroupId"
                                                    onChange={(e) => setSearch({ ...search, yearGroupId: e.target.value })}
                                                    value={search.yearGroupId}
                                                >
                                                    <option value="">Search By Year Group</option>
                                                    {grades.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="invoiceStatus"
                                                    onChange={(e) => setSearch({ ...search, invoiceStatus: e.target.value })}
                                                    value={search.invoiceStatus}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {studentStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={students}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListStudentInvoices;