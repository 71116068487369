import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { downloadStudentInvoice, getStudentInvoiceDetailsById } from '@app/services/student';
import CurrencyFormat from 'react-currency-format';
import jsPDF from "jspdf";
import moment from 'moment';
import fileDownload from 'js-file-download';

const ViewStudentInvoice = () => {

    let obj: any = {}
    const pdfRef = useRef(null);
    let { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState(obj);

    useEffect(() => {
        getInvoiceDetailsById(id)
    },[]);

    const getInvoiceDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getStudentInvoiceDetailsById(id);
            if(resp.success === true){
                setLoading(false);
                setInvoice(resp.collections);
                if(Object.keys(resp?.collections).length == 0){
                    setLoading(false);
                    toast.error('Something went wrong');
                    navigate('/student-invoices');
                }                    
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/student-invoices');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error');
            navigate('/student-invoices');
        }
    };

    const exportToPDF = () => {
		let doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});
		doc.html(pdfRef.current, {
			callback: function (doc) {
				doc.save('Invoice-FCL0000' + id);
			},
			x: 25,
			y: 25,
			html2canvas: {
				scale: 0.37,
    
			},
            
		});
	}

    const downloadInvoicePDF = async () => {
        try {
            const resp = await downloadStudentInvoice(id);
            fileDownload(resp, `${invoice?.invoice_number}.pdf`)
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong');
        }
    };

    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>View Invoice Details</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href="/student-invoices">Manage Student Invoices</a></li>
                                <li className="breadcrumb-item active">View Invoice Details</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {/* Main content */}
                            <div className="invoice p-3 mb-3" ref={pdfRef}>
                                {/* title row */}
                                <div className="row">
                                    <div className="col-2">
                                        <img
                                            src="../../../img/FCL-logo.png"
                                            alt="Logo"
                                            style={{ maxWidth: "120px" }}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <b> First Class Learning Ltd </b> <br />
                                        4th Floor <br />
                                        The Blade, <br />
                                        Abbey Street, <br />
                                        Reading, RG1 3BE <br />
                                        United Kingdom
                                    </div>
                                    <div className='col-3'>
                                        <div className=''>
                                            <span className="float-right"><b>Student Name :</b> { invoice?.student_name }</span>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <h4>
                                            <small className="float-right">Date: { invoice?.invoice_date ? moment(invoice?.invoice_date).format('DD/MM/YYYY') : '---'}</small>
                                        </h4>
                                    </div>
                                    {/* /.col */}
                                </div>
                                <hr/>
                                {/* info row */}
                                <div className="row invoice-info">
                                    <div className="col-sm-4 invoice-col">
                                        From
                                        <address>
                                            <strong>{ invoice?.franchise_name }</strong><br />
                                            { invoice?.franchise_address ?? '---'}<br />
                                            Phone: { invoice?.franchise_phone ?? '---'}<br />
                                            Email: { invoice?.franchise_email ?? '---'}
                                        </address>
                                    </div>
                                    {/* /.col */}
                                    <div className="col-sm-4 invoice-col">
                                        To
                                        <address>
                                            <strong>{ invoice?.parent?.name ?? '---'}</strong><br />
                                            { invoice?.parent?.address }<br />
                                            Phone: { invoice?.parent?.phone }<br />
                                            Email: { invoice?.parent?.email }
                                        </address>
                                    </div>
                                    {/* /.col */}
                                    <div className="col-sm-3 invoice-col border rounded mb-2">
                                        <h4><b>Invoice</b></h4>
                                        <b>Number : { invoice?.invoice_number ?? '---'}</b><br />
                                        Invoice From : <br />{ invoice?.invoice_from_date ? moment(invoice?.invoice_from_date).format('DD-MM-YYYY') + ' To ' + moment(invoice?.invoice_to_date).format('DD-MM-YYYY') : '---'}<br />
                                        Due By : { invoice?.invoice_due_date ? moment(invoice?.invoice_due_date).format('DD-MM-YYYY') : '---'}<br />
                                        VAT Number : { invoice?.franchise_vat ?? '---'}<br />
                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                                {/* Table row */}
                                <div className="row">
                                    <div className="col-12 table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Net</th>
                                                    <th>VAT</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { invoice?.subject_fee && invoice?.subject_fee.map((row, i) => (
                                                    <tr key={'subject_fee' + i}>
                                                        <td>Subject Fee</td>
                                                        <td>{ row?.name }</td>
                                                        <td><CurrencyFormat value={ (parseFloat(row?.net_amount)).toFixed(4) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                        <td><CurrencyFormat value={ (parseFloat(row?.vat_amount)).toFixed(4) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                        <td><CurrencyFormat value={ (parseFloat(row?.total_amount)).toFixed(4) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                    </tr>
                                                ))}

                                                { invoice?.fees && invoice?.fees.map((row, i) => (
                                                    <tr key={'fees' + i}>
                                                        <td>Fee</td>
                                                        <td>{ row?.description }</td>
                                                        <td><CurrencyFormat value={ (parseFloat(row?.net_amount)).toFixed(4) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                        <td><CurrencyFormat value={ (parseFloat(row?.vat_amount)).toFixed(4) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                        <td><CurrencyFormat value={ (parseFloat(row?.invoiced_amount)).toFixed(4) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                    </tr>
                                                ))}

                                                { invoice?.credits && invoice?.credits.map((row, i) => (
                                                    <tr key={'credits' + i}>
                                                        <td>Credit</td>
                                                        <td>{ row?.description }</td>
                                                        <td><CurrencyFormat value={ (parseFloat(row?.net_amount)).toFixed(4) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                        <td><CurrencyFormat value={ (parseFloat(row?.vat_amount)).toFixed(4) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                        <td><CurrencyFormat value={ (parseFloat(row?.invoiced_amount)).toFixed(4) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                    </tr>
                                                ))}
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                                <div className="row">
                                    {/* accepted payments column */}
                                    <div className="col-6">
                                        {/* <p className="lead">Payment Methods:</p>
                                        <img src="../../dist/img/credit/visa.png" alt="Visa" />
                                        <img src="../../dist/img/credit/mastercard.png" alt="Mastercard" />
                                        <img src="../../dist/img/credit/american-express.png" alt="American Express" />
                                        <img src="../../dist/img/credit/paypal2.png" alt="Paypal" />
                                        <p className="text-muted well well-sm shadow-none" style={{marginTop: 10}}>
                                            Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles, weebly ning heekya handango imeem
                                            plugg
                                            dopplr jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.
                                        </p> */}
                                    </div>
                                    {/* /.col */}
                                    <div className="col-6">
                                        {/* <p className="lead">Amount Due 2/22/2014</p> */}
                                        <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{width: '50%'}}>Net :</th>
                                                        <td><CurrencyFormat value={ (parseFloat(invoice?.total_net_amount)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ invoice?.franchise_currency + ' '} /></td>
                                                    </tr>
                                                    <tr>
                                                        <th>VAT { invoice?.vat_percent ? `(${invoice?.vat_percent} %)`: ''} :</th>
                                                        <td><CurrencyFormat value={ (parseFloat(invoice?.total_vat_amount)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={invoice?.franchise_currency + ' '} /></td>
                                                    </tr>
                                                    { invoice?.credits && invoice?.credits.length > 0 && (
                                                        <tr>
                                                            <th>Credit Used :</th>
                                                            <td><CurrencyFormat value={ (parseFloat(invoice?.credit_amount)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={invoice?.franchise_currency + ' - '} /></td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <th>Total :</th>
                                                        <td><CurrencyFormat value={ (parseFloat(invoice?.total_payable_amount)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={invoice?.franchise_currency + ' '} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                                {/* this row will not appear when printing */}
                                <div className="row no-print" data-html2canvas-ignore="true">
                                    <div className="col-12">
                                        {/* <Link className="btn btn-default mr-2" to='/student-invoices'>Back</Link> */}
                                        {/* 
                                            <a href="invoice-print.html" rel="noopener" target="_blank" className="btn btn-default"><i className="fas fa-print" /> Print</a> 
                                            <button type="button" className="btn btn-success float-right"><i className="far fa-credit-card" /> Submit Payment </button>
                                        */}
                                        <button type="button" className="btn btn-primary" onClick={downloadInvoicePDF} style={{marginRight: 5}}>
                                            <i className="fas fa-download" /> Download PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* /.invoice */}
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
};

export default ViewStudentInvoice;