import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { getAllSubjectModules } from '@app/services/module';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { downloadStudentHandbooksByWorksheets, getStudentWorkHistoryWithPagination } from '@app/services/student';
import { useSelector } from 'react-redux';
import printJS from 'print-js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const WorkHistory = () => {

    let { id, roll_number, first_name, last_name, grade_name, franchise_name, date_of_birth, gender } =  useSelector((state: any) => state.auth.selectedStudent)
    const [selectedRows, SetSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [history, setHistory] = useState([]);
    const [modules, setModules] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({fromDate: '', toDate: '', moduleId: ''});
    const [toDateError, setToDateError] = useState('');
    const [fromDateError, setFromDateError] = useState('');
    const canAccess = useSelector((state: any) => state.auth.permissions['student-work'])

    const navigate = useNavigate()
    const [t] = useTranslation()
    useEffect(() => {
        if(canAccess.summary == false){
            toast.error(t('auth.noAccess'))
            navigate('/')
        }
    }, [])

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        
        if(search.fromDate != '' && search.toDate == ''){
            setToDateError('Please select To date')
        }else{
            setToDateError('')
        }

        if(search.fromDate == '' && search.toDate != ''){
            setFromDateError('Please select From date')
        }else{
            setFromDateError('')
        }

        let fromDate = new Date(search.fromDate).getTime();
        let toDate = new Date(search.toDate).getTime();

        if((search.fromDate == '' && search.toDate == '') || (search.fromDate != '' && search.toDate != '')){
            if (fromDate > toDate) {
                setFromDateError('From date must be less than To date')
            } else {
                setFromDateError('')
                getStudentWorkHistoryData(1, perPage, sortField, sortDirection);
            }
        }
    },[search]);

    useEffect(() => {
        getAllModuleData();
    },[]);

    const getStudentWorkHistoryData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getStudentWorkHistoryWithPagination(id, page, perPage, sortField, sortDirection, search);
            if (resp?.success === true) {
                setLoading(false);
                setHistory(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error')
        }
    };

    const getAllModuleData = async () => {
        try {
            const modulesResp = await getAllSubjectModules();
            if (modulesResp.success === true) {
                setModules(modulesResp.collections);
            } else {
                toast.error(modulesResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error')
        }
    };

    const columns = [
        {
            name: 'Assignment Date',
            selector: (row: { assignment_date: string; }) => row.assignment_date,
            cell: (row: { assignment_date: string; }) => (
                row.assignment_date ? moment(row.assignment_date).format('DD/MM/YYYY, dddd') : '---'
            ),
            sortable: true,
            sortField: 'assignment_date',
        },
        {
            name: 'Module',
            selector: (row: { name: string, worksheet_number:number }) => row.name +'-'+ row.worksheet_number ,//`${row.name} - ${row.worksheet_number}`, 
            sortable: true,
            sortField: 'module_name',
        }
    ];

    const handlePageChange = page => {
        getStudentWorkHistoryData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getStudentWorkHistoryData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
        SetSelectedRows([])
        setToggleCleared(!toggleCleared)
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getStudentWorkHistoryData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({fromDate: '', toDate: '', moduleId: ''})
        SetSelectedRows([])
        setToggleCleared(!toggleCleared)
    };
    
    const handleSelectedRows = ({ selectedRows }) => {
        SetSelectedRows(selectedRows)
    };

    const handlePrintHandbooks = async() => {
        try{
            if(selectedRows.length > 0){
                setLoading(true);
                let payload = {
                    student_id: id,
                    selectedRows: selectedRows
                }

                const resp = await downloadStudentHandbooksByWorksheets(payload);
                
                //Build a URL from the file
                const fileURL = URL.createObjectURL(resp);
                
                printJS({printable:fileURL, type:'pdf', showModal:true})
                setLoading(false);        
            }else{
                toast.error('Select worksheet(s) to print the handbooks')
                return
            }
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Work History</span>
                        </div>
                        <div className="col-12">
                            <div className="external-event" style={{backgroundColor: "#ffd316", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '15%', fontWeight: 600, cursor:'default'}}>Student Details</div>
                            <div className="card">
                                <div className="card-body" style={{backgroundColor: '#f9f1cc'}}>
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <div className="form-group">
                                                <dt>Roll No. </dt>
                                                <dd className="form-control-plaintext">{ roll_number ?? '---' } </dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <dt>Student Name</dt>
                                                <dd className="form-control-plaintext">{ ( first_name ?? '---' )+ ' ' + ( last_name ?? '---')} </dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <dt>DOB</dt>
                                                <dd className="form-control-plaintext">{date_of_birth ? moment(date_of_birth).format('DD/MM/YYYY') : '---'} </dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <dt>Gender</dt>
                                                <dd className="form-control-plaintext">{gender ? gender.charAt(0).toUpperCase() + gender.slice(1) : '---'} </dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <dt>Year Group</dt>
                                                <dd className="form-control-plaintext">{ grade_name ?? '---'} </dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Franchise Name</dt>
                                                <dd className='form-control-plaintext'>{ franchise_name ?? '---'}</dd>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Work History</h3>
                                    <div className='d-flex justify-content-end'>
                                        <div className="row">
                                            <div className="ml-2">
                                                {/* <Button type="button" isLoading={isLoading} 
                                                    onClick={handlePrintHandbooks} 
                                                    className="btn btn-default ml-2" 
                                                    disabled={selectedRows.length == 0}><i className="fas fa-print"></i> Print Selected Handbook(s)</Button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <label>From Date</label>
                                            <Form.Control 
                                                type="date" 
                                                name="fromDate" 
                                                id="fromDate" 
                                                placeholder="Enter from date" 
                                                value={moment(search.fromDate).format('YYYY-MM-DD')}
                                                onChange={(e) => setSearch({ ...search, fromDate: e.target.value })}
                                            />
                                            <div className="text-danger">
                                                { fromDateError }
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>To Date</label>
                                            <Form.Control 
                                                type="date" 
                                                name="toDate" 
                                                id="toDate" 
                                                placeholder="Enter to date" 
                                                value={moment(search.toDate).format('YYYY-MM-DD')}
                                                onChange={(e) => setSearch({ ...search, toDate: e.target.value })}
                                            />
                                            <div className="text-danger">
                                                { toDateError }
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>Module</label>
                                            <select
                                                className="form-control"
                                                name="moduleId"
                                                onChange={(e) => setSearch({ ...search, moduleId: e.target.value })}
                                                value={search.moduleId}
                                            >
                                                <option value="">Search By Module</option>
                                                {modules.map((obj) => (
                                                    <option key={obj.id} value={obj.id}>
                                                        {obj.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-2' style={{marginTop: '32px'}}>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        // title="Work History"
                                        columns={columns}
                                        data={history}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                        // selectableRows
                                        // onSelectedRowsChange={handleSelectedRows}
                                        clearSelectedRows={toggleCleared}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </>
    )
};

export default WorkHistory;