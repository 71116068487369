import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import moment from 'moment';
import { getRestrictedCartDetails } from '@app/services/order';
import { Col, Row } from 'react-bootstrap';
import "../../pages/order-form/OrderForm.css";

const ViewRestrictedCart = () => {
    let obj: any = {modules:{english:[], mathematics:[]}, other_items:[]}
    const [isLoading, setLoading] = useState(false);
    const [order, setOrderData] = useState(obj);
    const navigate = useNavigate();
    let { franchise_id, user_id } = useParams();
    const franchiseTypes = [{value:'in-house', label:'In-house'}, {value:'company', label:'Limited Company'}, {value:'partnership', label:'Partnership'}, {value:'sole_trader', label:'Sole Trader'}];

    useEffect(() => {
        getRestrictedCartDetailsData({franchise_id: franchise_id, user_id: user_id});
    }, []);

    const getRestrictedCartDetailsData = async (payload) => {
        try {
            setLoading(true);
            const resp = await getRestrictedCartDetails(payload);
            if(resp?.success === true){
                setLoading(false);
                setOrderData(resp?.collections);
            }else{
                setLoading(false);
                toast.error(resp?.message);
                navigate('/restricted-franchise-carts');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error')
        }
    };

    return (
        <div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">View Restricted Cart</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Franchise</dt>
                                                <dd>{ order?.franchise_name }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Franchise Type</dt>
                                                <dd><span className="badge badge-info">{ (franchiseTypes.find(obj => { return obj.value === order?.franchise_type}))?.label } </span></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Kashflow Order Account </dt>
                                                <dd>{ order?.kashflow_order_account ?? '---' }</dd>
                                            </div>
                                        </div> 
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Contact Number</dt>
                                                <dd>{ order?.contact_no ?? '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Created By </dt>
                                                <dd>{ order?.user_name ?? '---' }</dd>
                                            </div>
                                        </div> 
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Created At </dt>
                                                <dd>{moment(order?.created_at).format('DD-MM-YYYY hh:mm A')}</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Status </dt>
                                                <dd><span className="badge badge-danger">{ order.order_status ? order.order_status.charAt(0).toUpperCase() + order.order_status.slice(1) : '---' }</span></dd>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className='modul_sec_wrapper'>
                                    <div className='container-fluid'>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-12 col-xl-12">
                                                <div className="content" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    <h6 className="mb-4 grey_wrapper_title d-flex justify-content-between">Cart Summary 
                                                        <span>
                                                            <b>Total Sets Ordered:</b> { order?.qty_sets ? order?.qty_sets : 0 } <br></br>
                                                            <b>Total Handbooks Ordered:</b> { order?.qty_handbooks ? order?.qty_handbooks : 0 }
                                                        </span>
                                                    </h6>
                                                    { order?.modules['english'] && (
                                                        <div className="content_inner_wrap" key={1}>
                                                            <Row>
                                                                <Col xl={12}>
                                                                    <b>English</b>
                                                                </Col>
                                                                <Col xl={12}>
                                                                { (order?.modules['english']).map((module) => (
                                                                    <div className="list_module_wrapper" key={module?.slug}>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                <h6 className="mb-4 grey_wrapper_title">
                                                                                    { module.name } ({ module.description })
                                                                                </h6>
                                                                            </Col>
                                                                            <Col xl={4} md={4} sm={4}>
                                                                                <table className="table table-bordered" id="client_id_table">
                                                                                    <tbody>
                                                                                        { module?.allsets[0]?.qty && (
                                                                                            <tr>
                                                                                                <th> Entire Module</th>
                                                                                                <td> { module?.allsets[0]?.qty }</td>
                                                                                            </tr>
                                                                                        )}
                                                                                        { module?.handbooks.length > 0 && module?.handbooks.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook
                                                                                                    {handbook?.combined_name && (
                                                                                                        <span className="font-italic font-weight-light ml-2">({handbook?.combined_name})</span>
                                                                                                    )}
                                                                                                </th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        { module?.handbook_suppliments.length > 0 && module?.handbook_suppliments.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook Supplements</th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Col>
                                                                        
                                                                            { (module?.blocks).length > 0 && (
                                                                                <Col xl={4} md={4} sm={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Blocks</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.blocks.map((block, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (block?.name).slice(4) }</td>
                                                                                                    <td> { block?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                            { (module?.sets).length > 0 && (
                                                                                <Col xl={4} md={4} sm={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Sets</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.sets.map((set, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (set?.name).slice(4) }</td>
                                                                                                    <td> { set?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    { order?.modules['mathematics'] && (
                                                        <div className="content_inner_wrap" key={2}>
                                                            <Row>
                                                                <Col xl={12}>
                                                                    <b>Mathematics</b>
                                                                </Col>
                                                                <Col xl={12}>
                                                                { (order?.modules['mathematics']).map((module) => (
                                                                    <div className="list_module_wrapper" key={module?.slug}>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                <h6 className="mb-4 grey_wrapper_title">
                                                                                    { module.name } ({ module.description })
                                                                                </h6>
                                                                            </Col>
                                                                            <Col xl={4}>
                                                                                <table className="table table-bordered" id="client_id_table">
                                                                                    <tbody>
                                                                                        { module?.allsets[0]?.qty && (
                                                                                            <tr>
                                                                                                <th> Entire Module</th>
                                                                                                <td> { module?.allsets[0]?.qty }</td>
                                                                                            </tr>
                                                                                        )}
                                                                                        { module?.handbooks.length > 0 && module?.handbooks.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook
                                                                                                    {handbook?.combined_name && (
                                                                                                        <span className="font-italic font-weight-light ml-2">({handbook?.combined_name})</span>
                                                                                                    )}
                                                                                                </th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        { module?.handbook_suppliments.length > 0 && module?.handbook_suppliments.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook Supplements</th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Col>
                                                                            { (module?.blocks).length > 0 && (
                                                                                <Col xl={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Blocks</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.blocks.map((block, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (block?.name).slice(4) }</td>
                                                                                                    <td> { block?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                            { (module?.sets).length > 0 && (
                                                                                <Col xl={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Sets</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.sets.map((set, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (set?.name).slice(4) }</td>
                                                                                                    <td> { set?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    { order?.modules['science'] && (
                                                        <div className="content_inner_wrap" key={3}>
                                                            <Row>
                                                                <Col xl={12}>
                                                                    <b>Science</b>
                                                                </Col>
                                                                <Col xl={12}>
                                                                { (order?.modules['science']).map((module) => (
                                                                    <div className="list_module_wrapper" key={module?.slug}>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                <h6 className="mb-4 grey_wrapper_title">
                                                                                    { module.name } ({ module.description })
                                                                                </h6>
                                                                            </Col>
                                                                            <Col xl={4} md={4} sm={4}>
                                                                                <table className="table table-bordered" id="client_id_table">
                                                                                    <tbody>
                                                                                        { module?.allsets[0]?.qty && (
                                                                                            <tr>
                                                                                                <th> Entire Module</th>
                                                                                                <td> { module?.allsets[0]?.qty }</td>
                                                                                            </tr>
                                                                                        )}
                                                                                        { module?.handbooks.length > 0 && module?.handbooks.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook
                                                                                                    {handbook?.combined_name && (
                                                                                                        <span className="font-italic font-weight-light ml-2">({handbook?.combined_name})</span>
                                                                                                    )}
                                                                                                </th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        { module?.handbook_suppliments.length > 0 && module?.handbook_suppliments.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook Supplements</th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Col>
                                                                        
                                                                            { (module?.blocks).length > 0 && (
                                                                                <Col xl={4} md={4} sm={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Blocks</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.blocks.map((block, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (block?.name).slice(4) }</td>
                                                                                                    <td> { block?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                            { (module?.sets).length > 0 && (
                                                                                <Col xl={4} md={4} sm={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Sets</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.sets.map((set, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (set?.name).slice(4) }</td>
                                                                                                    <td> { set?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    { (order?.other_items).length > 0 && (
                                                        <div className="content_inner_wrap" key={4}>
                                                            <Row>
                                                                <Col xl={12}>
                                                                    <b>Other Items</b>
                                                                </Col>
                                                                <Col xl={12}>
                                                                    <div className="list_module_wrapper">
                                                                        <Row>
                                                                            <Col xl={6}>
                                                                                <table className="table table-bordered" id="client_id_table">
                                                                                    <tbody>
                                                                                        <tr className='text-center'>
                                                                                            <th> Item</th>
                                                                                            <th> Quantity</th>
                                                                                        </tr>
                                                                                        { (order?.other_items).map((item, i) => (
                                                                                            <tr className='text-center'>
                                                                                                <td> { item.name }</td>
                                                                                                <td> { item.qty } </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="card-footer">
                                    <Link className="btn btn-default" to='/restricted-franchise-carts'>Close</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ViewRestrictedCart;