import { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import { Button } from "@app/components";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';
import { clearCart, loadCart, setStep } from "@app/store/reducers/studentCount";
import { getCartStudCountByFranchise, getTransactionDetails, makePayment, placeOrder } from "@app/services/studentCount";

const CheckoutForm = () => {

    let { id } = useParams();
	let obj: any = {}
	// Setup state to track client secret, errors and checkout status
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [apiError, setAPIError] = useState(null);
	const [processing, setProcessing] = useState(false);
	const [disabled, setDisabled] = useState(true);
    const [order, setOrderData] = useState(obj);
    const studCountStore = useSelector((state: any) => state.studentCount);
	const dispatch = useDispatch();
    const navigate = useNavigate();
	const [cardInfo, setCardInfo] = useState({
		name: "",
		expiry: "",
		number: "",
		address: {
			line: "",
			postalCode: "",
		},
	});

	// Store reference to Stripe
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		if(id){
			getOrderById(id);
		}else{
			getCartStudCountByFranchiseData(studCountStore?.franchise?.id);
		}
    }, []);

    const getOrderById = async (id) => {
        try {
            setProcessing(true);
            const resp = await getTransactionDetails(id);
            if(resp?.success === true){
                setProcessing(false);
				if(!(resp?.collections?.status === 'failed' || resp?.collections?.status === 'unpaid')){
					toast.error('Something went wrong, please try again!');
					navigate('/student-count-transactions');
				}
                setOrderData(resp?.collections);
            }else{
                setProcessing(false);
                toast.error(resp?.message);
                navigate('/student-count-transactions');
            }
        } catch (error: any) {
            setProcessing(false);
            toast.error(error?.message || 'Internal server error');
        }
    };

	const getCartStudCountByFranchiseData = async (franchiseId) => {
        try {
            setProcessing(true);
            const resp = await getCartStudCountByFranchise(franchiseId);
			if (resp?.success === true) {
				setProcessing(false);
				if(Object.keys(resp?.collections).length === 0){
					toast.warning('Something went wrong, please try again!');
					dispatch(setStep(1));
					dispatch(clearCart());
					navigate('/student-count-transactions');
				}else{
					dispatch(loadCart(resp?.collections));
				}
            } else {
				setProcessing(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setProcessing(false);
            toast.error(error?.message || 'Internal server error');
        }
    };

	const handleChange = async (event) => {
		// Listen for changes in the CardElement and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const handleChangeAddressLine = (e) => {
		const { value } = e.target;
		setCardInfo((prev) => {
		  	return { ...prev, address: { ...prev.address, line: value } };
		});
	}

	const handleChangeName = (e) => {
		const { value } = e.target;
		setCardInfo((prev) => {
		  	return { ...prev, name: value };
		});
	}

	const handleSubmit = async (ev) => {
		ev.preventDefault();
		setProcessing(true);
		const address = cardInfo.address;
		const billingDetails = {
			name: cardInfo.name,
			address: {
				line1: address.line,
			},
		};
		
		try{
			await stripe.createPaymentMethod({
				type: "card",
				billing_details: billingDetails,
				card: elements.getElement(CardElement),
			}) .then( async (resp) => {
				if(resp.paymentMethod){
					setError(null);
					const paymentMethod = resp.paymentMethod;
					if(id && order?.status === 'failed'){
						makePaymentAPI({order_id: id, payment_method_id: paymentMethod?.id})
					}else{
						const payload = {
							franchise_id: id ? order?.franchise_id : studCountStore?.franchise?.id,
							payment_method_id: paymentMethod?.id,
							amount: id ? order?.total_price : studCountStore?.cart?.total_price
						}
						placeOrderAPI(payload);
					} 
				}else{
					setError(resp?.error?.message);
					setDisabled(true);
					setProcessing(false);
				}
			}).catch((err) => {
				console.log('Promise catch: ', err.error.message);
			});
		} catch (err) {
			setError(`Payment failed: ${err.error.message}`)
			setDisabled(true);
			setProcessing(false);
		}
	};

	const placeOrderAPI = async (payload) => {
		try{
			setProcessing(true);
			const res = await placeOrder(payload);
			if(res?.success === true){
				if(res?.collections?.status === "requires_action"){
					const { paymentIntent, error } = await stripe.confirmCardPayment(
						res.collections.client_secret
					);
					if (error){
						setProcessing(false);
						toast.error(error?.message, {autoClose:7000});
						setAPIError(error?.message);
						return;
					} 
					if (paymentIntent.status === "succeeded"){
						payload.payment_intent_id = paymentIntent.id;
						placeOrderAPI(payload);
					}else{
						setProcessing(false);
						toast.error('We are unable to authenticate your payment method. Please choose a different payment method and try again.', {autoClose:7000});
						setAPIError('We are unable to authenticate your payment method. Please choose a different payment method and try again.');
					}
				}else{
					setSucceeded(true);
					setProcessing(false);
					toast.success(res?.message);
					dispatch(setStep(1));
					dispatch(clearCart());
					navigate('/student-count-transactions');
				}
			}else{
				setProcessing(false);
				toast.error(res?.errors[0]?.msg, {autoClose:7000});
				setAPIError(res?.errors[0]?.msg);
				// dispatch(setStep(4));
			}
		} catch (error: any) {
			setProcessing(false);
			toast.error(error?.message || 'Internal server error');
		}
	}

	const makePaymentAPI = async (payload) => {
		try {
			const res = await makePayment(payload);
			if(res?.success === true){
				if(res?.collections?.status === "requires_action"){
					const { paymentIntent, error } = await stripe.confirmCardPayment(
						res.collections.client_secret
					);
					if (error){
						setProcessing(false);
						toast.error(error?.message, {autoClose:7000});
						setAPIError(error?.message);
						return;
					} 
					if (paymentIntent.status === "succeeded"){
						payload.payment_intent_id = paymentIntent.id;
						makePaymentAPI(payload);
					}else{
						setProcessing(false);
						toast.error('We are unable to authenticate your payment method. Please choose a different payment method and try again.', {autoClose:7000});
						setAPIError('We are unable to authenticate your payment method. Please choose a different payment method and try again.');
					}
				}else{
					setSucceeded(true);
					setProcessing(false);
					toast.success(res?.message);
					navigate('/student-count-transactions');
				}
			}else{
				setProcessing(false);
				setDisabled(true);
				toast.error(res?.error, {autoClose:7000});
				setAPIError(res?.error);
				toast.error(res?.errors[0]?.msg || 'Internal server error');
			}
		} catch (error: any) {
            setProcessing(false);
            toast.error(error?.message || 'Internal server error');
        }
	}

	// const paylater = async () => {
	// 	const payload = {
	// 		franchise_id: studCountStore?.franchise?.id,
	// 		payment_method_id: 'paylater',
	// 		amount: studCountStore?.cart?.total_price
	// 	}
	// 	await placeOrderAPI(payload);
	// }

	const handleCancel = (e) => {
		id ? navigate('/student-count-transactions') : dispatch(setStep(3));
	}

	return (
		<>
		<div className="payWrapper" id="payWrapper">
			<p className="text-danger">{ apiError }</p>
			<form id="payment-form" onSubmit={handleSubmit}>
			<div className="innerWrapper">
				<div className="title">Make Payment</div>
				<div className="row pl-2">
					<label>Name On Card</label>
					<input
						onChange={handleChangeName}
						type="text"
						name="name"
						placeholder="Enter card holder name"
					/>
				</div>
				<label>Card Details</label>
				<div className="rowPaymentInput">
					<CardElement
						id="card-element"
						options={{}}
						onChange={handleChange}
					/>
				</div>
				<div className="addressWrapper">
					<div className="row pl-2">
						<label>Full Address</label>
						<input
							onChange={handleChangeAddressLine}
							type="text"
							name="address"
							placeholder="Enter full address"
						/>
					</div>
					<div className="btnContainer">
						<Button type="button" isLoading={processing} onClick={ handleCancel } className="btn btn-danger mr-2"> Cancel </Button>
						{/* { !id && (
							<Button type="button" isLoading={processing} onClick={ paylater } className="btn btn-success mr-2"> Pay Later </Button>
						)} */}
						<Button id="submit" type="submit" isLoading={processing} className="btn btn-primary mr-2" disabled={processing || disabled || succeeded}> Pay <CurrencyFormat value={ id ? order?.total_price :studCountStore?.cart?.total_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></Button>
					
						{/* <button disabled={processing || disabled || succeeded} id="submit">Pay</button> */}
					</div>
					{error && (
						<div className="text-danger" role="alert">{error}</div>
					)}
					<p className={succeeded ? "text-success" : "text-success d-none"}>Payment succeeded!</p>
				</div>
			</div>
			</form>
			<div className="mt-2" id="3d-secure-wrapper"></div>
		</div>
		</>
	);
};

export default CheckoutForm;