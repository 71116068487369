import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { downloadStudentHandbooksByWeeks, getStudentSubjectsById, getStudentWorkSummaryById } from '@app/services/student';
import moment from 'moment';
import printJS from 'print-js';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const WorkSummary = () => {

    const canAccess = useSelector((state: any) => state.auth.permissions['student-work']);
    const navigate = useNavigate()
    const [t] = useTranslation()
    useEffect(() => {
        if(canAccess.summary == false){
            toast.error(t('auth.noAccess'))
            navigate('/')
        }
    }, [])

    let { id, roll_number, first_name, last_name, grade_name, franchise_name, date_of_birth, gender } =  useSelector((state: any) => state?.auth?.selectedStudent)
    const [isLoading, setLoading] = useState(false);
    const [isHandbookLoading, setHanbooksLoading] = useState(false);
    const [weeklyWork, setWeeklyWork] = useState([]);
    const [studentWork, setStudentWork] = useState([]);
    const [numOfWeeks, setNumOfWeeks] = useState(0);
    const [weeks, setWeeks] = useState([]);
    const [activeWeek, setActiveWeek] = useState(1);
    const [streamPerWeek, setStreamPerWeek] = useState(0);
    const studentWorkStore = useSelector((state: any) => state.studentWork);
    const [search, setSearch] = useState({subject_id: studentWorkStore?.subject?.id ?? 0});
    const [subjects, setSubjects] = useState([]);
    const [stream1, setStream1] = useState('');
    const [stream2, setStream2] = useState('');
    const [stream3, setStream3] = useState('');
    const [stream4, setStream4] = useState('');
    const [selectedWeeks, setSelectedWeeks] = useState([]);
    const [weekError, setWeekError] = useState('');

    useEffect(() => {
        getStudentAllActiveSubjects(id)
        getStudentWorkSummary(id, search.subject_id)
    },[]);

    const getStudentAllActiveSubjects = async (id) => {
        try {
            setLoading(true)
            const resp = await getStudentSubjectsById(id)
            if (resp.success === true) {
                setLoading(false)
				setSubjects([{id: 0, name: 'Mix and Match'}].concat(resp?.collections))
            } else {
				setLoading(false)
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			setLoading(false)
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getStudentWorkSummary = async (id, subject_id) => {
        try {
            setLoading(true)
            const resp = await getStudentWorkSummaryById({student_id: id, subject_id: subject_id});
            if (resp.success === true) {
                setLoading(false)
                setStudentWork(resp?.collections?.summary)
                setStreamPerWeek(resp?.collections?.modules_per_week)
                setNumOfWeeks(resp?.collections?.summary?.length)
                if(resp?.collections?.summary?.length > 0){
                    setWeeklyWork(resp?.collections?.summary[0])
                    if(resp?.collections?.modules_per_week >= 1)
                        setStream1(resp?.collections?.summary[0][0]?.modules.length > 0 ? (resp?.collections?.summary[0][0].modules.find(obj => { return obj.module == 'module1'}))?.learning_pathway : '')
                    if(resp?.collections?.modules_per_week >= 2)
                        setStream2(resp?.collections?.summary[0][0]?.modules.length > 0 ? (resp?.collections?.summary[0][0].modules.find(obj => { return obj.module == 'module2'}))?.learning_pathway : '')
                    if(resp?.collections?.modules_per_week >= 3)
                        setStream3(resp?.collections?.summary[0][0]?.modules.length > 0 ? (resp?.collections?.summary[0][0].modules.find(obj => { return obj.module == 'module3'}))?.learning_pathway : '')
                    if(resp?.collections?.modules_per_week >= 4 )
                        setStream4(resp?.collections?.summary[0][0]?.modules.length > 0 ? (resp?.collections?.summary[0][0].modules.find(obj => { return obj.module == 'module4'}))?.learning_pathway : '')
                }else{
                    setWeeklyWork([])
                }
                let weeksArr = [];
                for (let i = 0; i < resp?.collections?.summary?.length; i++) {
                    weeksArr.push({id: i, name: 'Week '+ (i + 1)})
                }
                setWeeks(weeksArr);
            } else {
                setLoading(false)
                toast.error(resp?.errors[0]?.msg)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.message || 'Internal server error');
        }
    };

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }

    const getPaginationContent = () => {
        let content = [];
        for (let i = 1; i <= numOfWeeks; i++) {
            content.push(<li className="page-item" key={i}><a className="page-link" style={{backgroundColor: activeWeek == i ? '#dee2e6' : '#fff'}} href="#" onClick={ (e) => changeWeek(e, i)}>Week {i}</a></li>);
        }
        return content;
    };

    const changeWeek = (e, week) => {
        e.preventDefault();
        setActiveWeek(week);
        setWeeklyWork(studentWork[week - 1]);
    }

    const handleSubjectChange = async (subject_id) => {
        setSearch({ ...search, subject_id: subject_id })
        getStudentWorkSummary(id, subject_id)
        setSelectedWeeks([])
    };

    const onSelectWeeks = (options: readonly typeof Option[]) => {
        setSelectedWeeks([].slice.call(options).map((option) => option.id))
        if([].slice.call(options).map((option) => option.id).length > 0){
            setWeekError('')
        }
    };

    const handlePrintHandbooks = async() => {
        try{
            if(selectedWeeks.length > 0){
                setHanbooksLoading(true);
                setWeekError('')
                let dates = [];
                selectedWeeks.sort()
                selectedWeeks.map((week) => {
                    studentWork[week].map((obj) => {
                        dates.push(obj.date)
                    })
                })
                let payload = {
                    student_id: id,
                    subject_id: search.subject_id,
                    weeks: selectedWeeks.length,
                    dates: dates
                }

                const resp = await downloadStudentHandbooksByWeeks(payload);
                
                //Build a URL from the file
                const fileURL = URL.createObjectURL(resp);
                
                printJS({printable:fileURL, type:'pdf', showModal:true})
                setHanbooksLoading(false);        
            }else{
                setWeekError('Select week(s) to print the handbooks')
                return
            }
        } catch (error) {
            setHanbooksLoading(false);
            console.log(error)
        }
    }

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Work Summary</span>
                    </div>
                    <div className="col-md-12">
                        <div className="external-event" style={{backgroundColor: "#ffd316", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '15%', fontWeight: 600, cursor:'default'}}>Student Details</div>
                        <div className="card">
                            <div className="card-body" style={{backgroundColor: '#f9f1cc'}}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <dt>Roll No. </dt>
                                            <dd className="form-control-plaintext">{ roll_number ?? '---' } </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <dt>Student Name</dt>
                                            <dd className="form-control-plaintext">{ ( first_name ?? '---' )+ ' ' + ( last_name ?? '---')} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <dt>DOB</dt>
                                            <dd className="form-control-plaintext">{date_of_birth ? moment(date_of_birth).format('DD/MM/YYYY') : '---'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <dt>Gender</dt>
                                            <dd className="form-control-plaintext">{gender ? gender.charAt(0).toUpperCase() + gender.slice(1) : '---'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <dt>Year Group</dt>
                                            <dd className="form-control-plaintext">{ grade_name ?? '---'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <dt>Franchise Name</dt>
                                            <dd className='form-control-plaintext'>{ franchise_name ?? '---'}</dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Work Summary {studentWork.length == 0 ? '' : `- Week ${activeWeek}`}</h3>
                                <div className='d-flex justify-content-end'>
                                    <div className="row">
                                        <div className="">
                                            <Select
                                                // ref={selectRef}
                                                isMulti
                                                name="week"
                                                placeholder="Select week(s) to print"
                                                options={weeks}
                                                getOptionValue={(option)=>`${option['id']}`}
                                                getOptionLabel={(option)=>`${option['name']}`}
                                                onChange={ (e) => {onSelectWeeks(e)}}
                                                value={
                                                    weeks?.length
                                                    ? weeks.filter((obj) =>
                                                        selectedWeeks.includes(obj.id)
                                                        )
                                                    : selectedWeeks
                                                } // set selected values
                                                styles={customStylesSelect}
                                                isLoading={isLoading}
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                            />
                                            <div className='text-danger'>
                                                { weekError }
                                            </div>
                                        </div>
                                        <div className="ml-2">
                                            <Button type="button" isLoading={isHandbookLoading} onClick={handlePrintHandbooks} className="btn btn-default ml-2" disabled={studentWork.length==0}><i className="fas fa-print"></i> Print Handbook(s)</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <label>You are viewing work for the subject</label>
                                        <select
                                            className="form-control"
                                            name="subject_id"
                                            onChange={(e) => handleSubjectChange(e.target.value)}
                                            value={search.subject_id}
                                        >
                                            {subjects.length > 0 && subjects.map((obj) => (
                                                <option key={obj.id} value={obj.id}>
                                                    {obj.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <table className="table table-bordered">
                                    { weeklyWork.length > 0 && (
                                    <thead>
                                        <tr>
                                            <th style={{ width: 10 }}>#</th>
                                            <th>Date</th>
                                            { streamPerWeek >= 1 && (
                                                <th>{ stream1 }</th>
                                            )}
                                            { streamPerWeek >= 2 && (
                                                <th>{ stream2 }</th>
                                            )}
                                            { streamPerWeek >= 3 && (
                                                <th>{ stream3 }</th>
                                            )}
                                            { streamPerWeek >= 4 && (
                                                <th>{ stream4 }</th>
                                            )}
                                        </tr>
                                    </thead>
                                    )}
                                    <tbody>
                                        {weeklyWork.length > 0 && weeklyWork.map((obj, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}.</td>
                                                <td>{ moment(obj.date).format('DD/MM/YYYY, dddd') }</td>
                                                { streamPerWeek >= 1 && (
                                                    <td>{ obj?.modules !== null ? ( Array.prototype.map.call(obj?.modules.filter(mod => mod.module == 'module1'), function(module) { return module?.name + '-' + module?.worksheet_number }) ).join(', ') : '---'}</td>
                                                )}
                                                { streamPerWeek >= 2 && (
                                                    <td>{ obj?.modules !== null ? ( Array.prototype.map.call(obj?.modules.filter(mod => mod.module == 'module2'), function(module) { return module?.name + '-' + module?.worksheet_number }) ).join(', ') : '---'}</td>
                                                )}
                                                { streamPerWeek >= 3 && (
                                                    <td>{ obj?.modules !== null ? ( Array.prototype.map.call(obj?.modules.filter(mod => mod.module == 'module3'), function(module) { return module?.name + '-' + module?.worksheet_number }) ).join(', ') : '---'}</td>
                                                )}
                                                { streamPerWeek >= 4 && (
                                                    <td>{ obj?.modules !== null ? ( Array.prototype.map.call(obj?.modules.filter(mod => mod.module == 'module4'), function(module) { return module?.name + '-' + module?.worksheet_number }) ).join(', ') : '---'}</td>
                                                )}
                                            </tr>
                                        ))}
                                        {weeklyWork.length == 0 && (
                                            <tr>
                                                <td className='text-center' colSpan={3}>There are no work summary to display</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                            <div className="card-footer clearfix">
                                <ul className="pagination pagination-sm m-0 float-right">
                                    { getPaginationContent() }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default WorkSummary;