import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import { deleteFranchiseById, getFranchisesWithPagination } from '@app/services/franchise';
import { getFranchiseAdmins } from '@app/services/user';
import moment from 'moment';
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import fileDownload from 'js-file-download';
import UpdateVATSettingsModal from './UpdateVATSettingsModal';

const ListFranchise = () => {

    const [franchise, setFranchise] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({name: '', type: '', address: '', phone: '', franchisee: '', status: '', kashflowLicenseAccount: ''});
    const userStatus = [{label:'Active', value:'active'}, {label:'Inactive', value:'inactive'}, {label:'On Hold', value:'on_hold'}];
    const franchiseTypes = [{value:'in-house', label:'In-house'}, {value:'company', label:'Limited Company'}, {value:'partnership', label:'Partnership'}, {value:'sole_trader', label:'Sole Trader'}];
    const vatStutuses = [{value: 'inclusive' , label:'Inclusive'}, {value: 'exclusive', label:'Exclusive'}, {value: 'not_applicable', label:'N/A'}]
    const canAccess = useSelector((state: any) => state.auth.permissions.franchise);
    const canAccessNote = useSelector((state: any) => state.auth.permissions.notes);
    const [franchisees, setFranchisees] = useState([]);
    const [VATSettingPayload, setVATSettingPayload] = useState({});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
                // borderBottomWidth: '0px',
                // borderBottomColor: theme.divider.default,
                // borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        getAllFranchiseData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getFranchiseAdminData();
    }, [])
    
    const getAllFranchiseData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const usersResp = await getFranchisesWithPagination(page, perPage, sortField, sortDirection, search);
            if (usersResp.success === true) {
                setLoading(false);
                setFranchise(usersResp.collections.data);
                setTotalRows(usersResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(usersResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal Server Error')
        }
    };

    const getFranchiseAdminData = async () => {
        try {
            const franchiseeResp = await getFranchiseAdmins();
            if (franchiseeResp.success === true) {
                setFranchisees(franchiseeResp.collections);
            } else {
                toast.error(franchiseeResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal Server Error')
        }
    };

    const columns = [
        {
            name: 'Franchise Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'name',
            wrap: true,
        },
        {
            name: 'Type',
            selector: (row: { type: string; }) => row.type,
            cell: (row: { type: string; }) => (
                // row.type !== null ? (row.type === 'company' ? <span className="badge badge-info">Company</span> : <span className="badge badge-dark">Sole Trader </span>) : '---'
                row.type !== null ? <span className="badge badge-info">{ (franchiseTypes.find(obj => { return obj.value === row.type}))?.label } </span> : '---'
            ),
            sortable: true,
            sortField: 'type',
        },
        {
            name: 'Kashflow Licence Account',
            selector: (row: { kashflow_license_account: string; }) => row.kashflow_license_account,
            sortable: true,
            sortField: 'kashflow_license_account',
            wrap: true,
        },
        {
            name: 'Start Date',
            selector: (row: { effective_start_date: string; }) => moment(row.effective_start_date).format('DD/MM/YYYY'),
            sortable: true,
            sortField: 'effective_start_date',
            wrap: true,
        },
        {
            name: 'Franchisee(s)',
            selector: (row: { franchisee: string; }) => { return row.franchisee !== null ? ( Array.prototype.map.call(row.franchisee, function(user) { return user.first_name +' '+user.last_name; }).join(", ") ) : '---' },
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                // row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="capitalize badge bg-danger">{row.status}</span>
                row.status === 'active' ? <span className="badge bg-success">{ (userStatus.find(obj => { return obj.value === row.status}))?.label } </span> : <span className="badge bg-danger">{ (userStatus.find(obj => { return obj.value === row.status}))?.label } </span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number; name: string; enabled_student_subject_vat: boolean; vat_status: string; enabled_student_fee_credit_vat:boolean; standard_vat_percentage: string; }) => (
              <>
                { canAccess?.dashboard && 
                    <Link
                    to={`/franchise/dashboard/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Franchise Dashboard"
                    >
                    <i className="fas fa-tachometer-alt mr-2"/>
                    </Link>
                }
                { canAccess?.edit && 
                    <Link
                    to={`/franchise/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Edit Franchise"
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                }
                { canAccess['update-vat-settings'] && 
                    <a href="#" onClick={ (e) => handleUpdateVATSettings(e, {
                        id: row.id,
                        name: row.name,
                        enabled_student_subject_vat: row?.enabled_student_subject_vat,
                        vat_status: row?.vat_status,
                        enabled_student_fee_credit_vat: row?.enabled_student_fee_credit_vat,
                        standard_vat_percentage: row?.standard_vat_percentage}) } data-toggle="tooltip" title="Update VAT Settings"> 
                        <i className="fas fa-money-check mr-2"/>
                    </a> 
                }
                { canAccess?.view && 
                    <Link
                    to={`/franchise/view/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="View Franchise"
                    >
                    <i className="fas fa-eye mr-2"/>
                    </Link>
                }
                {/* { canAccess?.delete &&
                    <a href='#' onClick={ (e) => { deleteFranchiseConfirm(e, row.id)} } data-toggle="tooltip" title="Delete Franchise">
                        <i className="fas fa-trash mr-2"/>
                    </a>
                } */}
                { canAccess?.link && 
                    <Link
                    to={`/franchise/link/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Link Franchise"
                    >
                    <i className="fas fa-random mr-2"/>
                    </Link>
                }
                { canAccess['shipping-address'] && 
                    <Link
                    to={`/franchise/shipping-address/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Manage Shipping Address"
                    >
                    <i className="fas fa-shipping-fast mr-2"/>
                    </Link>
                }
                { (canAccess['view-contract'] || canAccess['view-contract-documents']) && 
                    <Link
                    to={`/franchise/view-contract/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="View FMS Contract"
                    >
                    <i className="fas fa-file-contract mr-2"/>
                    </Link>
                }
                { canAccessNote?.list && 
                    <Link
                    to={`/franchise/${row.id}/notes`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="List Notes"
                    >
                    <i className="fas fa-sticky-note mr-2"/>
                    </Link>
                }
              </>
            ),
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.default_franchise_group === true,
            style: {
                backgroundColor: 'rgb(228, 253, 254)',
                // color: 'white',
            },
        }
    ];

    const deleteFranchiseConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteFranchise(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteFranchise = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteFranchiseById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllFranchiseData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal Server Error')
          }
    };

    const handlePageChange = page => {
        getAllFranchiseData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllFranchiseData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllFranchiseData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({name: '', type: '', address: '', phone: '', franchisee: '', status: '', kashflowLicenseAccount: ''});
    };
    
    const exportCSV = async() => {
        try {
            setLoading(true);
            let records = await getFranchisesWithPagination(1, totalRows, sortField, sortDirection, search)
            if (records.success === true) {
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(records?.errors[0]?.msg);
                return;
            }

            if((records?.collections?.data).length === 0){
                toast.error('Records not found.')
                return;
            }

            const workbook = new ExcelJS.Workbook();
            workbook.addWorksheet("Franchise");
            const worksheet = workbook.getWorksheet('Franchise');
            worksheet.columns = [
                { header: "Franchise", key: "name" },
                { header: "Type", key: "type" },
                { header: "Kashflow Licence Account", key: "kashflow_license_account" },
                { header: "Kashflow Order Account", key: "kashflow_order_account" },
                { header: "Start Date", key: "start_date" },
                { header: "End Date", key: "end_date" },
                { header: "Postcodes", key: "postcodes" },
                { header: "Total Postcodes", key: "postcode_count" },
                { header: "Franchisee(s)", key: "franchisee" },
                { header: "Email(s)", key: "emails" },
                { header: "Phone", key: "phone" },
                { header: "Address", key: "address" },
                { header: "Status", key: "status" },
                { header: "English", key: "english" },
                { header: "Mathematics", key: "mathematics" },
                { header: "Science", key: "science" },
                { header: "VAT Status", key: "vat_status" },
                { header: "Standard VAT Percentage", key: "standard_vat_percentage" },
                { header: "Enabled Student Fee/Credit VAT?", key: "enabled_student_fee_credit_vat" },
                { header: "Enabled Student Subject VAT?", key: "enabled_student_subject_vat" },
                { header: "Is Student Count Mandatory?", key: "enabled_student_count" },
                { header: "Enabled Holiday Camp", key: "enabled_holiday_camp" },
                { header: "Enabled Adhoc Printing?", key: "enabled_adhoc_printing" },
                { header: "Enabled Local Printing?", key: "student_local_printing" },
                { header: "Enabled Manage Students?", key: "enabled_manage_students" },
                { header: "Enabled Recurring Invoice?", key: "enabled_recurring_invoice" },
                { header: "Enabled Material Orders?", key: "enabled_material_orders" },
            ];
            
            (records?.collections?.data).map((row) => {
                row.emails = row.franchisee !== null ? ( Array.prototype.map.call(row.franchisee, function(user) { return user.email; }).join(", ") ) : '---';
                row.franchisee = row.franchisee !== null ? ( Array.prototype.map.call(row.franchisee, function(user) { return user.first_name +' '+user.last_name; }).join(", ") ) : '---';
                row.postcode_count = (row.postcodes).length;
                row.postcodes = row.postcodes !== null ? ( Array.prototype.map.call(row.postcodes, function(postcode) { return postcode.name; }).join(", ") ) : '---';
                row.status = row.status.charAt(0).toUpperCase() + row.status.slice(1);
                row.phone = row.phone ? row.phone + '' : '---';
                row.address = row.address ? row.address : '---';
                row.type = (franchiseTypes.find(obj => { return obj.value === row.type}))?.label
                row.start_date = (row.start_date != null) ? moment(row.start_date).format('DD/MM/YYYY') : '---';
                row.end_date = (row.end_date != null) ? moment(row.end_date).format('DD/MM/YYYY') : '---';
                row.english = row.subjects.findIndex((subject) => subject.name === 'English') !== -1 ? 'Active' : 'Inactive';
                row.mathematics = row.subjects.findIndex((subject) => subject.name === 'Mathematics') !== -1 ? 'Active' : 'Inactive';
                row.science = row.subjects.findIndex((subject) => subject.name === 'Science') !== -1 ? 'Active' : 'Inactive';
                row.vat_status = (vatStutuses.find(obj => { return obj.value === row.vat_status}))?.label,
                row.enabled_student_fee_credit_vat = row?.enabled_student_fee_credit_vat ? 'Yes' : 'No',
                row.enabled_student_subject_vat = row?.enabled_student_subject_vat ? 'Yes' : 'No',
                row.enabled_student_count = row?.enabled_student_count ? 'Yes' : 'No',
                row.enabled_holiday_camp = row?.enabled_holiday_camp ? 'Yes' : 'No',
                row.enabled_adhoc_printing = row?.enabled_adhoc_printing ? 'Yes' : 'No',
                row.student_local_printing = row?.student_local_printing ? 'Yes' : 'No',
                row.enabled_manage_students = row?.enabled_manage_students ? 'Yes' : 'No',
                row.enabled_recurring_invoice = row?.enabled_recurring_invoice ? 'Yes' : 'No'
                row.enabled_material_orders = row?.enabled_material_orders ? 'Yes' : 'No'
            })

            worksheet.addRows(records?.collections?.data);
            let filename = 'Franchise-' + moment().format('DD-MMM-YYYY') + '.csv';
            workbook.csv.writeBuffer().then(function(buffer) {
                fileDownload(buffer, filename);
            });
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal Server Error')
        }
    };

    const handleUpdateVATSettings = async(e, payload) => {
        e.preventDefault()
        handleShow()
        setVATSettingPayload(payload)
    }

    const callbackGetAllFranchiseData = async() => {
        await getAllFranchiseData(currentPage, perPage, sortField, sortDirection)
    }

    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Manage Franchise</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">Manage Franchise</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Manage Franchise</h3>
                                    <div className="col-lg-12 text-right">
                                        { canAccess?.create && <Link className="btn btn-primary" to="/franchise/add" > + Add New Franchise </Link> }
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Franchise Name" />
                                        </div>
                                        <div className="col-sm-2">
                                            <select
                                                className="form-control"
                                                name="type"
                                                onChange={(e) => setSearch({ ...search, type: e.target.value })}
                                                value={search.type}
                                            >
                                                <option value="">Search By Type</option>
                                                {franchiseTypes.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-2">
                                            <input type="text" name='kashflowLicenseAccount' className="form-control" value={search.kashflowLicenseAccount} onChange={(e) => setSearch({ ...search, kashflowLicenseAccount: e.target.value })} placeholder="Kashflow Licence Account" />
                                        </div>
                                        {/* <div className="col-sm-2">
                                            <input type="text" name='phone' className="form-control" value={search.phone} onChange={(e) => setSearch({ ...search, phone: e.target.value })} placeholder="Search by phone" />
                                        </div> */}
                                        {/* <div className="col-sm-2">
                                            <input type="text" name='address' className="form-control" value={search.address} onChange={(e) => setSearch({ ...search, address: e.target.value })} placeholder="Search by address" />
                                        </div> */}
                                        { canAccess['list-search-by-franchisee'] && 
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="franchisee"
                                                    onChange={(e) => setSearch({ ...search, franchisee: e.target.value })}
                                                    value={search.franchisee}
                                                >
                                                    <option value="">Search By Franchisee</option>
                                                    {franchisees.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.first_name +' '+ obj.last_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        }
                                        <div className="col-sm-2">
                                            <select
                                                className="form-control"
                                                name="status"
                                                onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                value={search.status}
                                            >
                                                <option value="">Search By Status</option>
                                                {userStatus.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-2'>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning mr-2">Reset</Button>
                                            { canAccess?.['export-csv'] &&
                                                <Button type="submit" isLoading={isLoading} onClick={exportCSV} className="btn btn-success">Export</Button>
                                            }
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={franchise}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        // paginationComponent
                                        // selectableRows
                                        customStyles={customStyle}
                                        conditionalRowStyles={conditionalRowStyles}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}

            <UpdateVATSettingsModal
                show={show}
                handleClose={handleClose}
                payload={VATSettingPayload}
                callbackGetAllFranchiseData={callbackGetAllFranchiseData}
            />
        </div>
    )
};

export default ListFranchise;
