import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRestrictedCartsWithPagination } from '@app/services/order';
import { getAllActiveFranchise } from '@app/services/franchise';
import Select from "react-select";
import moment from 'moment';

const RestrictedFranchiseCarts = () => {

    const canAccess = useSelector((state: any) => state.auth.permissions.orders);
    let initSearch = {franchiseId: '', totalSets: '', totalHandbooks: ''};
    const selectRef = useRef(null);
    const [orders, setOrders] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [franchises, setFranchises] = useState([]);
    const [search, setSearch] = useState(initSearch);
    
    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }
      
    useEffect(() => {
        getRestrictedFranchiseCarts(1, perPage, sortField, sortDirection);
        getAllActiveFranchiseData();
    },[search]);

    const getRestrictedFranchiseCarts = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getRestrictedCartsWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp?.success === true) {
                setLoading(false);
                setOrders(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setFranchises(resp?.collections);
            } else {
                toast.error(resp?.message);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const columns = [
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name,
            sortable: true,
            sortField: 'franchise_name',
            wrap: true,
            // width: '20%'
        },
        {
            name: 'Contact No.',
            selector: (row: { contact_no: string; }) => row.contact_no ?? '---',
            sortable: true,
            sortField: 'contact_no',
            wrap: true,
            // width: '20%'
        },
        {
            name: 'Sets Qty',
            selector: (row: { qty_sets: string; }) => row.qty_sets,
            sortable: true,
            sortField: 'qty_sets',
        },
        {
            name: 'Handbooks Qty',
            selector: (row: { qty_handbooks: string; }) => row.qty_handbooks,
            sortable: true,
            sortField: 'qty_handbooks',
        },
        {
            name: 'Kashflow Order Account',
            selector: (row: { kashflow_order_account: string; }) => row.kashflow_order_account ?? '---',
            sortable: true,
            sortField: 'kashflow_order_account',
            wrap: true
        },
        {
            name: 'Created At',
            selector: (row: { created_at: string; }) => row.created_at,
            cell: (row: { created_at: string; }) => (
                row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : '---'
            ),
            sortable: true,
            sortField: 'created_at',
            wrap: true
        },
        {
            name: 'Created By',
            selector: (row: { user_name: string; }) => row.user_name,
            sortable: true,
            sortField: 'user_name',
            wrap: true
        },
        {
            name: 'Status',
            selector: (row: { order_status: string; }) => row.order_status,
            cell: (row: { order_status: string }) => (
                row.order_status !== null ? <span className="badge badge-danger">{ row.order_status.charAt(0).toUpperCase() + row.order_status.slice(1) } </span> : '---'
            ),
            sortable: true,
            sortField: 'order_status',
        },
        {  
            name: 'Action',
            cell: (row: { franchise_id:number, user_id:number }) => (
                <>
                    { canAccess?.['view-restricted-carts'] && 
                        <Link
                            to={`/restricted-franchise-carts/${row.franchise_id}/view/${row.user_id}`}
                            role="button"
                            state={{ id: row.franchise_id }}
                            data-toggle="tooltip" title="View Cart Details"
                        >
                        <i className="fas fa-eye mr-2"/>
                        </Link>
                    }
                </>
            ),
            omit: !canAccess?.['view-restricted-carts']
        },
    ];

    const handlePageChange = page => {
        getRestrictedFranchiseCarts(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getRestrictedFranchiseCarts(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getRestrictedFranchiseCarts(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch(initSearch);
    };

    return (
        <>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Restricted Franchise Carts</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">Restricted Franchise Carts</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Restricted Franchise Carts</h3>
                                    <div className=" text-right">
                                       
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-3">
                                            <Select
                                                ref={selectRef}
                                                name="franchiseId"
                                                placeholder="Search By Franchise"
                                                options={franchises}
                                                getOptionValue={(option)=>`${option['id']}`}
                                                getOptionLabel={(option)=>`${option['name']}`}
                                                onChange={ (option) => { setSearch({ ...search, franchiseId: option.id });}}
                                                styles={customStylesSelect}
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <input type="text" name='totalSets' className="form-control" value={search.totalSets} onChange={(e) => setSearch({ ...search, totalSets: e.target.value })} placeholder="Search By Sets" />
                                        </div>
                                        <div className="col-sm-2">
                                            <input type="text" name='totalHandbooks' className="form-control" value={search.totalHandbooks} onChange={(e) => setSearch({ ...search, totalHandbooks: e.target.value })} placeholder="Search By Handbooks" />
                                        </div>
                                        <div className='col-sm-2'>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={orders}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                        highlightOnHover
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </>
    )
};

export default RestrictedFranchiseCarts;