import {createSlice} from '@reduxjs/toolkit';

export interface AuthState {
  isLoggedIn: boolean;
  // token: string | null;
  currentUser: any;
  selectedRole: any;
  selectedStudent: any;
  permissions: any;
  recoverPasswordEmail: string | null;
}

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  // token: localStorage.getItem('token'),
  currentUser: {
    id: null,
    first_name: null,
    last_name: null,
    phone: null,
    email: 'mail@example.com',
    roles: [],
    students: [],
    picture: null,
    token: null
  },
  selectedRole: null,
  selectedStudent: null,
  permissions: [],
  recoverPasswordEmail: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, {payload}) => {
      localStorage.setItem('token', payload);
      state.isLoggedIn = true;
      // state.token = payload;
      localStorage.removeItem('Temptoken');
    },
    logoutUser: (state) => {
      localStorage.removeItem('token');
      state.currentUser = {};
      state.isLoggedIn = false;
      // state.token = null;
      state.selectedRole = null;
      state.selectedStudent = null;
      state.permissions = null;
    },
    loadUser: (state, {payload}) => {
      state.currentUser = payload;
      localStorage.setItem('Temptoken', payload.token);
    },
    setSelectedRole: (state, {payload}) => {
      state.selectedRole = payload;
    },
    setPermissions: (state, {payload}) => {
      state.permissions = payload;
    },
    setRecoverPasswordEmail: (state, {payload}) => {
      state.recoverPasswordEmail = payload;
    },
    setStudents: (state, {payload}) => {
      state.currentUser.students = payload;
    },
    setSelectedStudent: (state, {payload}) => {
      state.selectedStudent = payload;
    },
  }
});

export const {loginUser, logoutUser, loadUser, setSelectedRole, setPermissions, setRecoverPasswordEmail, setStudents, setSelectedStudent} = authSlice.actions;

export default authSlice.reducer;
