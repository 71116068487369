import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { Button } from '@app/components';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getSubjectsByFranchise } from '@app/services/franchise';
import { createStudentCount, getMonthsByFranchiseId } from '@app/services/studentCount';
import { setStep } from '@app/store/reducers/studentCount';

const AddStudentCount = () => {

    const [isLoading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const months = [{id: 1, name:'January'}, {id: 2, name:'February'}, {id: 3, name:'March'}, 
                    {id: 4, name:'April'}, {id: 5, name:'May'}, {id: 6, name:'June'},
                    {id: 7, name:'July'},{id: 8, name:'August'},{id: 9, name:'September'},
                    {id: 10, name:'October'}, {id: 11, name:'November'}, {id: 12, name:'December'}];
    const years = Array.from( { length: 2 }, (_, index) => new Date().getFullYear() - index );
    const sub = {mathematics: false, english: false, science: false};
    const [subjects, setSubjects] = useState(sub);
    const [monthsCount, setMonthsCount] = useState([]);
    const studCountStore = useSelector((state: any) => state.studentCount);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            franchise_id: studCountStore?.franchise?.id ?? '',
            year: year ?? '',
            months: monthsCount
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
            year: Yup.string().required('Please select year'),
            months: Yup.array().of(Yup.object({
                month: Yup.string().required('Please select month'),
                mathematics: Yup.number().when([], {
                    is: () => !subjects?.mathematics,
                    then: Yup.number().required('Please enter mathematics count')
                        .min(0,'Count must be a greater than or equal to 0').max(1000, 'Rate must be less than or equal to 1000'),
                    otherwise: Yup.number(),
                }),
                english: Yup.number().when([], {
                    is: () => !subjects?.english,
                    then: Yup.number().required('Please enter english count')
                        .min(0,'Count must be a greater than or equal to 0').max(1000, 'Rate must be less than or equal to 1000'),
                    otherwise: Yup.number(),
                }),
                science: Yup.number().when([], {
                    is: () => !subjects?.science,
                    then: Yup.number().required('Please enter science count')
                        .min(0,'Count must be a greater than or equal to 0').max(1000, 'Rate must be less than or equal to 1000'),
                    otherwise: Yup.number(),
                }),
                total: Yup.number(),
            })),
        }),
        onSubmit: (values) => {
            {(values.months).map((count) => (
                count.total = count.mathematics + + count.english + count.science
            ))}
            addStudentCountAPI(values);
            // setErrorMsg('');
            // formik.resetForm();
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if(formik.values.franchise_id != '' && formik.values.year != ''){
            getDefaultersAPI({franchise_id: formik.values.franchise_id, year: formik.values.year})
        }
    },[formik.values.franchise_id, formik.values.year]);

    useEffect(() => {
        if(formik.values.franchise_id != ""){
            getAllActiveSubjectsData(formik.values.franchise_id);
        }
    },[formik.values.franchise_id]);

    const getDefaultersAPI = async (payload) => {
        try {
            const resp = await getMonthsByFranchiseId(payload)
            if (resp?.success === true) {
                let months = [];
                formik.resetForm({ values: 
                    {
                        franchise_id: studCountStore?.franchise?.id ?? '',
                        year: year ?? '',
                        months: []
                    } 
                });
                if(payload?.year == (new Date(studCountStore?.franchise?.effective_start_date).getFullYear())){
                    (resp?.collections).map((obj) => {
                        if(payload?.year == (new Date().getFullYear())){
                            if(obj.month >= (new Date(studCountStore?.franchise?.effective_start_date).getMonth() + 1) && obj.month < (new Date().getMonth() + 1)){
                                months.push({month: obj.month, mathematics: obj.mathematics, english: obj.english, science: obj.science})
                            }
                        }else{
                            if(obj.month >= (new Date(studCountStore?.franchise?.effective_start_date).getMonth() + 1)){
                                months.push({month: obj.month, mathematics: obj.mathematics, english: obj.english, science: obj.science})
                            }
                        }
                    })
                }else if(payload?.year > (new Date(studCountStore?.franchise?.effective_start_date).getFullYear())){
                    if(payload?.year == (new Date().getFullYear())){
                        (resp?.collections).map((obj) => {
                            if(obj.month < (new Date().getMonth() + 1)){
                                months.push({month: obj.month, mathematics: obj.mathematics, english: obj.english, science: obj.science})
                            }
                        })
                    }else{
                        (resp?.collections).map((obj) => {
                            months.push({month: obj.month, mathematics: obj.mathematics, english: obj.english, science: obj.science})
                        })
                    }
                }
                setMonthsCount(months)
                // if(formik.values.franchise_id != ""){
                //     getAllActiveSubjectsData(formik.values.franchise_id);
                // }
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    }

    const getAllActiveSubjectsData = async (franchise_id) => {
        try {
            setLoading(true);
            const resp = await getSubjectsByFranchise(franchise_id);
            if (resp.success === true) {
                setLoading(false);
                if(resp.collections){
                    (resp.collections).map((subject)=> {
                        switch(subject?.name){
                            case 'Mathematics': sub.mathematics = subject.status == 'active' ? false : true;
                                                break;
                            case 'English': sub.english = subject.status == 'active' ? false : true;
                                            break; 
                            case 'Science': sub.science = subject.status == 'active' ? false : true;
                                            break; 
                            default:  break; 
                        }
                    })
                    setSubjects(sub);
                }
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error');
        }
    };

    const addStudentCountAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await createStudentCount(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                setMonthsCount([]);
                setYear(new Date().getFullYear());
                setErrorMsg('');
                formik.resetForm();
                dispatch(setStep(3));
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error');
        }
    };

    const handleBack = () => {
		dispatch(setStep(1));
	}

    const handleProceed = () => {
		dispatch(setStep(3));
	}

    return (
    <div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-primary">
                        <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-body">
                                <div className='row' style={{borderBottom: '1px solid #d1d1d1'}}>
                                    <span className='text-danger mb-2'>{ errorMsg }</span>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Year <span className="required">*</span></label>
                                            <Field as="select" name="year" className="form-control" 
                                                value={formik.values.year}
                                                onChange={(e) => { 
                                                    formik.setFieldValue('year', e.target.value)
                                                    setYear(e.target.value)
                                                }} 
                                            >
                                                <option value="">--Please select--</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}

                                            </Field>
                                            <div className="text-danger">
                                                <ErrorMessage name="year" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FieldArray name="months">
                                    {({ push, remove }) => (
                                        <>
                                        {(formik.values.months).length > 0 && formik.values.months.map((month, index) => (
                                            <div className='row mt-2' style={{borderBottom: '1px solid #d1d1d1'}} key={index}>
                                                <div className="col-sm-3" key={'month'+index}>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Month <span className="required">*</span></label>
                                                        <Field as="select" name={`months.${index}.month`} className="form-control" value={month.month} disabled>
                                                            {months.map((obj) => (
                                                                <option key={obj.name} value={obj.id}>
                                                                    {obj.name}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        <div className="text-danger">
                                                            <ErrorMessage name={`months.${index}.month`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3" key={'math'+index}>
                                                    <div className="form-group">
                                                        <label htmlFor="mathematics">Mathematics { !subjects?.mathematics && <span className="required">*</span> }</label>
                                                        <Field type="number" id="exampleInputEmail1" className="form-control" placeholder="Enter count" onWheel={ (e) => e.currentTarget.blur() }  name={`months.${index}.mathematics`} disabled={subjects?.mathematics}/>
                                                        <div className="text-danger">
                                                            <ErrorMessage name={`months.${index}.mathematics`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3" key={'english'+index}>
                                                    <div className="form-group">
                                                        <label htmlFor="english">English { !subjects?.english && <span className="required">*</span> }</label>
                                                        <Field type="number" id="exampleInputEmail1" className="form-control" placeholder="Enter count" onWheel={ (e) => e.currentTarget.blur() } name={`months.${index}.english`} disabled={subjects?.english}/>
                                                        <div className="text-danger">
                                                            <ErrorMessage name={`months.${index}.english`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3" key={'science'+index}>
                                                    <div className="form-group">
                                                        <label htmlFor="science">Science { !subjects?.science && <span className="required">*</span> }</label>
                                                        <Field type="number" id="exampleInputEmail1" className="form-control" placeholder="Enter count" onWheel={ (e) => e.currentTarget.blur() } name={`months.${index}.science`} disabled={subjects?.science}/>
                                                        <div className="text-danger">
                                                            <ErrorMessage name={`months.${index}.science`} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        {(formik.values.months).length == 0 && (
                                            <p className='mt-2 text-danger'>All the data for this year is up to date.</p>
                                        )}
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                            <div className="card-footer"> 
                                <span>
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2"> Next </Button>
                                    <Button type="button" onClick={ handleBack } className="btn btn-default mr-2"> Back </Button>
                                </span>
                                {/* { (studCountStore?.cart?.details)?.length > 0 && ( */}
                                    {/* <span className='float-right'>
                                        <Button type="submit" onClick={ handleProceed } className="btn btn-success text-right"><i className="fas fa-cart-plus fa-lg mr-2"></i>Go to Cart </Button>
                                    </span> */}
                                {/* )} */}
                            </div>
                        </form>
                        </FormikProvider>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddStudentCount
